const API_BASE = "fleet.lkw-walter.com";

const setAPI = () => {
    return "https://" + API_BASE + "/api";
};

const config = {
    API_URL: setAPI(API_BASE),
    WS_URL: "wss://" + API_BASE,
    STACK_NAME: "lkw-walter",
    VERSION: "1.14.4.3",
    DELAY_FILTER: 1500,
    DELAY_AUTOCOMPLETE: 490,
    DELAY_VALIDATION: 500,
    CARDFULLSIZE: 750,
    LIFETIME_GLOBAL_MSG: 5000,
    LIFETIME_GLOBAL_SUCCESS_MSG: 2000,
    UI_TABLE_OFFSET: 250,
    // 0 = LOG/DEBUG
    // 1 = INFO
    // 2 = WARN
    // 3 = ERROR
    LOG_LEVEL: 0,
    PLANNED_REPAIR_REQUIRED: "04 December 2022 00:00:00 UTC",
    CHIPER_IV: "4SLTuEwIDAQABAoG",
    CHIPER_KEY: "4SLTuEwIDAQABAoGADfVlY9S6mrI2cmd"
};

export default config;
